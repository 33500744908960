import { createSlice } from "@reduxjs/toolkit";
import { createResetState, creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { ScheduleRule } from "@/services/courseScheduling/settingRules";

interface InitialState {
  currentAddedRule: ScheduleRule | undefined;
}

const initialState: InitialState = {
  currentAddedRule: undefined,
};

const settingRulesSlice = createSlice({
  name: "settingRules",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setSettingRulesState = creatSetState("settingRules");
export const resetSettingRulesState = createResetState("settingRules");

export default settingRulesSlice;
