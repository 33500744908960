import process from "process";
import devConstants from "./constants.development";
import testConstants from "./constants.test";
import prdConstants from "./constants.production";
import localConstants from "./constants.local";
import previewConstants from "./constants.preview";
import devopsConstants from "./constants.devops";
import refactorConstants from "./constants.refactor";
import releasePreviewConstants from "./constants.release-preview";

const envConstantsMap = {
  production: prdConstants,
  test: testConstants,
  development: devConstants,
  local: localConstants,
  preview: previewConstants,
  devops: devopsConstants,
  refactor: refactorConstants,
  "release-preview": releasePreviewConstants,
};

// @ts-ignore
const envConstants = envConstantsMap[process.env.DEPLOY_ENV] || devConstants;

export const {
  SERVER_URL,
  DEPLOY_DIR,
  APP_URL,
  H5_STATIC_SERVER_URL,
  WX_APPID,
  WX_MP_APP_ID,
  SHOW_MAI_DIAN_LOG,
  ALLOW_MOBILE_LOGIN_PAGE,
  BASE_JW_APP_URL,
} = envConstants;

// 后端服务前缀
export const BASE_SYSTEM_USER = "/base-system-user";
export const SUPPORT_SERVICE = "/ttfs-support";
export const BASE_SCHOOL_COURSE = "/base-school-course";
export const BASE_SCHOOL_TEACHER = "/base-school-teacher";
export const BASE_SCHOOL = "/base-school";
export const SCHEDULING_ENGINE = "/schedule/engine/scheduleproject";
export const BASE_PARSE = "/base-parse";
export const BASE_SALES = "/base-sales";
export const BASE_USER_BEHAVIOR = "/base-user-behavior";
export const BASE_NCEE_CLASS_SCHEDULING = "/base-nceeclassscheduling"; // 新版课表
export const BASE_ARRANGE_EXAM_ROOM = "/base-arrangexamroom"; // 排监考
export const BASE_SCHEDULING = "/base-newscheduling"; // 新版排课
export const BASE_NEW_SCHEDULING = "/base-newscheduling"; // 新版排课

// 前端localStorage相关Key
export const QUICK_SCHEDULE_TOKEN = "QUICK_SCHEDULE_TOKEN"; // token
export const QUICK_CURRENT_VERSIO = "QUICK_CURRENT_VERSIO"; // 登录前选择的版本
export const QUICK_SCHEDULE_SCHOOL_INFO = "QUICK_SCHEDULE_SCHOOL_INFO"; // 学校信息
export const QUICK_SCHEDULE_SCHEMA_SHARED = "QUICK_SCHEDULE_SCHEMA_SHARED"; // 是否已经分享
export const QUICK_SCHEDULE_HAS_TOUR = "QUICK_SCHEDULE_HAS_TOUR"; // 是否已经引导
export const QUICK_SCHEDULE_TIP_ODD = "QUICK_SCHEDULE_TIP_ODD"; // 是否已经提示单双周操作
export const QUICK_SCHEDULE_TIP_COMBINE = "QUICK_SCHEDULE_TIP_COMBINE"; // 是否已经提示合班操作
export const QUICK_SCHEDULE_TIP_STRATIFIED = "QUICK_SCHEDULE_TIP_STRATIFIED"; // 是否已经提示分层操作
export const QUICK_SCHEDULE_TIP_SORT = "QUICK_SCHEDULE_TIP_SORT"; // 是否已经提示排序操作
export const QUICK_SCHEDULE_TIP_BATCH_INPUT = "QUICK_SCHEDULE_TIP_BATCH_INPUT"; // 是否已经提示批量操作
export const QUICK_SCHEDULE_IMPORT_SHEETS_DATA = "QUICK_SCHEDULE_IMPORT_SHEETS_DATA"; // 导入文件后的表格数据
export const QUICK_SCHEDULE_VERSION_LOG = "QUICK_SCHEDULE_VERSION_LOG"; // 快排课版本
export const QUICK_SCHEDULE_USER_ID = "QUICK_SCHEDULE_USER_ID"; // 用户id
export const QUICK_SCHEDULE_INVITER_ID = "QUICK_SCHEDULE_INVITER_ID"; // 邀请人id
export const QUICK_SCHEDULE_INVITER_STEPS = "QUICK_SCHEDULE_INVITER_STEPS"; // 被邀请人已经完成的操作记录（游客状态使用）
export const KUAIPK_TIPS = "kuaipk_tips"; // 提示集合
export const KUAIPK_SELECT_SUBJECT_CURRENT_TASK = "KUAIPK_SELECT_SUBJECT_CURRENT_TASK"; // 提示集合
export const KUAIPK_DIVIDE_CLASS_CURRENT_TASK = "KUAIPK_DIVIDE_CLASS_CURRENT_TASK"; // 提示集合
export const KUAIPK_DIVIDE_INVIGILATE_CURRENT_TASK = "KUAIPK_DIVIDE_INVIGILATE_CURRENT_TASK"; // 排监考当前taskId
export const KUAIPK_SCHEDULE_CURRENT_TASK_ID = "KUAIPK_SCHEDULE_CURRENT_TASK_ID"; // 当前方案id
export const KUAIPK_NEW_COURSE_SCHEDULE_CURRENT_TASK_ID =
  "KUAIPK_NEW_COURSE_SCHEDULE_CURRENT_TASK_ID"; // 走班排课当前任务ID
export const KUAIPK_CURRENT_TRAINING_COURSE_SCHEDULE_ID =
  "KUAIPK_CURRENT_TRAINING_COURSE_SCHEDULE_ID"; // 培训班排课当前taskId
export const KUAIPK_CURRENT_TEAM = "KUAIPK_CURRENT_TEAM"; // 排课当前团队信息
export const KUAIPK_PREVIEW_EXPORT_SELECT_TEMPLATEID = "KUAIPK_PREVIEW_EXPORT_SELECT_TEMPLATEID"; // 排课预览导出选中模板ID
export const KUAIPK_PREVIEW_EXPORT_PARAMS = "KUAIPK_PREVIEW_EXPORT_PARAMS"; // 排课预览导出参数缓存

export const KUAIJZ_MP_APP_ID = "wxb31a32b804116817"; // 倍思家长微信小程序appid
export const VISITOR_FROM = "kuaipk_visitor_from"; // 用户来源
export const VISITOR_REF = "kuaipk_visitor_ref"; // 用户页面来源
