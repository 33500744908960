// 生产环境配置

export default {
  SERVER_URL: "https://api.basejy.com", // 后端服务域名
  DEPLOY_DIR: "/", // 项目部署的目录
  APP_URL: "https://kuaipk.basejy.com", // 本项目访问地址
  H5_STATIC_SERVER_URL: "https://h5.basejy.com", // h5页面访问地址
  WX_APPID: "wxae050b3b7db880a5", // 微信公众号appid
  WX_MP_APP_ID: "wxef1aa2457078e512", // 微信小程序appid
  HUO_SHAN_MAI_DIAN_APP_ID: 20000699, // 火山引擎埋点appid
  SHOW_MAI_DIAN_LOG: false, // 是否打印埋点日志到浏览器控制台
  ALLOW_MOBILE_LOGIN_PAGE: false, //false, // 是否打开手机登录页面
  BASE_JW_APP_URL: 'https://jw.basejy.com',
};
