import { useAppSelector } from "@/store";
import { useMemo } from "react";
import { IVipRoleType } from "@/services/user";

export function getVipType(vipList?: { tag: IVipRoleType; vip: boolean }[]) {
  const isExVIP = vipList?.some((item) => item.tag === "EX_VIP_KUAIPK" && item.vip);
  const isXzbVIP = vipList?.some((item) => item.tag === "CLASS_VIP" && item.vip);
  const isXzbVIP20240617 = vipList?.some((item) => item.tag === "VIP_KUAIPK_20240617" && item.vip);
  const isInsVIP = vipList?.some((item) => item.tag === "INS_VIP_KUAIPK" && item.vip);
  const isJwCgVip = vipList?.some((item) => item.tag === "KUAIJW_CLASS" && item.vip); // 倍思教务常规排课
  const isJwXgkVip = vipList?.some((item) => item.tag === "KUAIJW_NCEE_CLASS" && item.vip); // 倍思教务新高考排课

  const isPersonalVIP = isExVIP || isXzbVIP || isXzbVIP20240617;
  const isJwVip = isJwCgVip || isJwXgkVip;

  return {
    isExVIP,
    isXzbVIP,
    isXzbVIP20240617,
    isJwCgVip,
    isJwXgkVip,
    isPersonalVIP,
    isInsVIP,
    isJwVip,
  };
}

export default function useVipType() {
  const { userVipInfo } = useAppSelector((state) => ({
    userVipInfo: state.user.userVipInfo,
  }));

  return useMemo(() => {
    return getVipType(userVipInfo?.vipList);
  }, [JSON.stringify(userVipInfo)]);
}
